<template>
  <div class="nav">
    <div class="toptips" v-show="istips == true">
      By continuing to use our website and/or services you agree to the updated
      Privacy Policy and the use of cookies as well as our &nbsp;<router-link
        to="term"
        >Terms and Conditions</router-link
      >
      <!-- <i class="el-icon-close" @click="closexy()"></i> -->
      <div class="agree" @click="closexy()">Agree</div>
    </div>
    <div class="nav_main">
      <router-link to="/home">
        <div class="logo">
          <img :src="pageConfig.logo" @load="imgLoad" />
        </div>
      </router-link>

      <div class="menu" v-if="pageConfig.id == 8">
        <div class="menu">
          <div class="item"><router-link to="/"> Flights </router-link></div>
          <!-- <div class="item"><router-link to="/about">About Us</router-link></div> -->
          <div class="item" style="display: none">
            <a href="https://travel.aoflights.com/en/">Travel</a>
          </div>
          <!-- <div class="item">about</div> -->
          <div class="item" v-if="loginShow">
            <router-link to="/orderSearch">View My bookings</router-link>
          </div>
        </div>
        <div @click="drawer = true" class="tnzfj" style="margin-left: 6px">
          <img src="@/assets/img/airpl.png" alt="" />
          <span
            style="font-size: 16px; margin-left: 6px"
            class="el-icon-arrow-up"
            v-if="drawer"
          ></span>
          <span
            style="font-size: 16px; margin-left: 6px"
            class="el-icon-arrow-down"
            v-else
          ></span>
        </div>
        <el-drawer
          class="sortDrawer-box"
          :visible.sync="drawer"
          :direction="direction"
        >
          <div class="sortDrawer">
            <div class="sortDrawer-item" @click="tiaozhuan(1)">
              <img src="@/assets/img/topair.png" alt="" />Flights
            </div>
            <div class="sortDrawer-item" @click="tiaozhuan(2)">
              <img src="@/assets/img/tclvyou.png" alt="" />
              Travel
            </div>
            <div class="sortDrawer-item" v-if="loginShow" @click="tiaozhuan(3)">
              <img src="@/assets/img/tcchaxun.png" alt="" />
              Search Trips
            </div>
          </div>
        </el-drawer>
        <div class="zw">
          <!-- 占位置 -->
        </div>
      </div>
      <div class="menu" v-else>
        <div class="item"><router-link to="/">Home</router-link></div>
        <!-- <div class="item"><router-link to="/about">About Us</router-link></div> -->
        <div class="item">
          <router-link to="/Contact">Contact Us</router-link>
        </div>
        <!-- <div class="item">about</div> -->
        <div class="item" v-if="loginShow">
          <router-link to="/orderSearch">View My bookings</router-link>
        </div>
      </div>

      <div class="dropDown">
        <div class="tips">24/7 Support</div>
        <div class="tphone">
          <el-dropdown trigger="click">
            <span class="el-dropdown-link">
              <i class="el-icon-arrow-down el-icon-phone"></i>
              {{ pageConfig.tel_from_overseas
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item class="pwid">
                <!-- <div class="textl">Sydney</div> -->
                <div class="textm">
                  <div>General enquiries</div>
                  <div>From overseas</div>
                </div>
                <div class="textR">
                  <div>{{ pageConfig.tel_general_enquiries }}</div>
                  <div>{{ pageConfig.tel_from_overseas }}</div>
                </div></el-dropdown-item
              >
              <!-- <el-dropdown-item class="pwid"
                ><div class="textl">Melbourn</div>
                <div class="textm">
                  <div>Domestic</div>
                  <div>international</div>
                </div>
                <div class="textR">
                  <div>2-9233-8508</div>
                  <div>+612-9233-8508</div>
                </div></el-dropdown-item
              > -->
              <!-- <el-dropdown-item class="pwid"
                ><div class="textl">Brisbane</div>
                <div class="textm">
                  <div>Domestic</div>
                  <div>international</div>
                </div>
                <div class="textR">
                  <div>2-9233-8508</div>
                  <div>+612-9233-8508</div>
                </div></el-dropdown-item
              > -->
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="currencyType" v-if="isHome">
          <el-dropdown
            trigger="click"
            @command="handleCommand"
            placement="bottom"
          >
            <span class="el-dropdown-link">
              <img :src="bzTypeList[bzTypeIndex]?.icon" alt="" />
              <span>&nbsp;{{ option }}</span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="item.value"
                v-for="(item, index) in bzTypeList"
                :key="index"
              >
                <img :src="item.icon" alt="" />
                {{ item.value }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div class="isLogin" @click="setLoginStatus(true)" v-if="loginShow">
          Sign in
        </div>
        <div class="isCard" v-if="!loginShow">
          <el-popover
            placement="bottom"
            v-model="loginPopover"
            width="250"
            trigger="click"
          >
            <div class="Cardbox">
              <div class="userEmil" v-show="!loginShow">
                <h3>Hi</h3>
                <div class="emil">{{ user_info.email }}</div>
                <div class="aset">
                  <router-link to="/myinfo">Account Settings</router-link>
                  <!-- <span class="editPassword" @click="editPassword"
                    >Change password</span
                  > -->
                  <!-- Modify User Password -->
                  <!-- <router-link to="stroke">MUP</router-link> -->
                </div>
              </div>
              <div
                class="Signup"
                @click="setLoginStatus(true)"
                v-if="loginShow"
              >
                Signup / Login
              </div>
              <div class="itembox" v-if="!loginShow">
                <div class="rout">
                  <router-link to="/orderList?status=1"> My trips</router-link>
                </div>
                <!-- <div class="rout"><router-link to="/">Home</router-link></div>
                <div class="rout">
                  <router-link to="about">About Us</router-link>
                </div>
                <div class="rout">
                  <router-link to="contact"> Contact Us</router-link>
                </div> -->

                <div class="rout" v-if="!loginShow" @click="outLogin">
                  Logout
                </div>
              </div>
            </div>
            <svg
              slot="reference"
              t="1678263065586"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="11302"
              width="24"
              height="24"
            >
              <path
                d="M128 128v128h832V128H128z m0 320v128h832V448H128z m0 320v128h832V768H128z"
                p-id="11303"
                fill="#333333"
              ></path>
            </svg>
          </el-popover>
        </div>
      </div>
    </div>
    <login v-if="loginStatus"></login>
    <register v-if="registerStatus"></register>
    <agreement v-if="agreementStatus"></agreement>
    <forgotPassword v-if="forgotPwdStatus"></forgotPassword>
    <resetPassword v-if="resetPwdStatus"></resetPassword>
    <editPassword v-if="editPwdStatus"></editPassword>
  </div>
</template>

<script>
import login from "../login/login.vue";
import register from "../register/register.vue";
import agreement from "../agreement/agreement.vue";
import forgotPassword from "../forgotPassword/forgotPassword.vue";
import resetPassword from "../resetPassword/resetPassword.vue";
import editPassword from "../editPassword/editPassword.vue";
import CNY from "@/assets/img/round-flag-CN.svg";
import USD from "@/assets/img/round-flag-US.svg";
import AUD from "@/assets/img/round-flag-AU.svg";
import EUR from "@/assets/img/round-flag-FR.svg";
import { mapState } from "vuex";
import handleNotification from "./mixins/handleNotification";

export default {
  mixins: [handleNotification],
  components: {
    login,
    agreement,
    register,
    forgotPassword,
    resetPassword,
    editPassword,
  },
  data() {
    return {
      drawer: false,
      direction: "btt",

      bzTypeIndex: 0,
      istips: sessionStorage.getItem("istips") || true,
      option: "",
      // loginSatus: false,
      // loginShow: true,
      accountNumber: "",
      isHome: false,
      loginPopover: false,
      currencyIcon: {
        AUD,
        CNY,
        USD,
        EUR,
      },
    };
  },
  watch: {
    $route: {
      handler(newV) {
        this.isHome = newV.name === "home";
      },
      immediate: true,
    },
    pageConfig: {
      handler(newValue) {
        if (Object.values(newValue).length > 0) {
          this.option = this.pageConfig.default_currency.split(",")[0];
          this.handleCommand(this.option);
          // console.log(this.option);
        }
      },
      immediate: true,
    },
  },

  computed: mapState({
    loginSatus: (state) => state.login.loginStatus,
    loginShow: (state) => state.login.loginShow,
    user_info: (state) => state.login.userInfo,
    loginStatus: (state) => state.login.loginStatus,
    registerStatus: (state) => state.register.registerStatus,
    agreementStatus: (state) => state.register.agreementStatus,
    forgotPwdStatus: (state) => state.register.forgotPwdStatus,
    editPwdStatus: (state) => state.register.editPwdStatus,
    resetPwdStatus: (state) => state.register.resetPwdStatus,
    pageConfig: (state) => state.common.pageConfig,
    bzTypeList: function (state) {
      return (
        state.common.pageConfig?.default_currency
          ?.split(",")
          .map((currency) => {
            // console.log(currency);
            return {
              icon: this.currencyIcon[currency],
              value: currency,
            };
          }) || []
      );
    },
  }),
  methods: {
    tiaozhuan(str) {
      // console.log(str);
      this.drawer = false;
      if (str == 1) {
        this.$router.push("/");
      }
      if (str == 2) {
        window.location.href = "https://travel.aoflights.com/";
      }
      if (str == 3) {
        this.$router.push("/orderSearch");
      }
    },
    editPassword() {
      this.$store.dispatch("register/set_editPwdStatus", true);
    },
    closexy() {
      this.istips = false;
      sessionStorage.setItem("istips", false);
      this.setNotificationTop();
    },
    setLoginStatus(value) {
      this.$store.dispatch("login/set_loginstatus", value);
    },
    getCloseDialog(value) {
      this.$store.dispatch("login/set_loginstatus", value);
    },
    outLogin() {
      this.handleCommand(this.option);
      this.loginPopover = false;
      this.$store.dispatch("login/set_logout");
    },
    // 币种选择
    handleCommand(command) {
      // console.log(command);
      // // console.log("执行了");
      //   // console.log(command, command);
      var storage = window.sessionStorage;
      this.bzTypeIndex = this.bzTypeList.findIndex(
        (item) => item.value === command
      );
      storage.setItem("Currency", command);
      this.$store.dispatch("common/set_Currency", command);
      this.option = command;
    },
    // 卡片显隐
    isCard() {},
  },
};
</script>

<style lang="scss" scoped>
@import "./nav.scss";
</style>
